import * as React from 'react'
import Layout from '../components/layout'
import * as styles from '../styles/form.module.scss'

const FormSentPage = ({data, location}) => {

    console.log(location)

return (
    <Layout location={location} title="Form Sent Page">
        <h2>フォームが送信されました</h2>
        <div className={styles.fillwrapper}>
            <p>返信まで、今しばらくお待ちください。</p>
        </div>
    </Layout>
)
}
export default FormSentPage